import { Link } from "react-router-dom";
import { useContext } from "react";

import AuthContext from "../../store/auth-context";

const Header = () => {
  const authCtx = useContext(AuthContext);

  const logoutHandler = async () => {

    const apiUrl = process.env.REACT_APP_API_HOST
      ? process.env.REACT_APP_API_HOST
      : "https://sfpapi.videocreator.net";

    await fetch(apiUrl + "/auth/signout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authCtx.token,
      },
    });

    authCtx.logout();
  };

  return (
    <header className={"c-Header"}>
      <meta name="robots" content="noindex" />
      <div className={"container"}>
        <nav>
          <div>
            {authCtx.isLoggedIn ? (
              <>
                <Link to="/kampagnen">Kampagnen</Link>
                <Link to="/downloads">Downloads</Link>
                <a href="/media/assets/VIA_Nutzungsbedingungen_S-Com.pdf">Nutzungsbedingungen</a>
                {authCtx.role === "admin" || authCtx.role === "kampagnadmin" ? (<> <Link to="/admin">Admin</Link> </>) : null}
              </>
            ) : null}
            <a href="https://www.s-communication.de/impressum.html">Impressum</a>
          </div>
          {authCtx.isLoggedIn ? (
            <div className={"nav-button"} onClick={logoutHandler}>
              Logout
            </div>
          ) : (
            <Link to="/auth">Login</Link>
          )}
        </nav>
      </div>
    </header>
  );
};

export default Header;
